<template>
  <WcCommonModal title="選擇篩選方式" @cancel="close" @close="close" @ok="returnFilter">
    <div class="list-filter">
      <label>顏色</label>

      <div class="color-row">
        <div class="color-col-btn">
          <button class="btn btn-sm" :class="{'btn-dark': filterObject.color === -1, 'btn-outline-secondary': filterObject.color !== -1}" @click="filterObject.color = -1">全部顏色</button>
        </div>
        <div class="color-col-sel">
          <ColorSelect v-model="filterObject.color"></ColorSelect>
        </div>
      </div>
    </div>
  </WcCommonModal>
</template>

<script>
import ColorSelect from '@/components/closet/ColorSelect.vue';
import WcCommonModal from '@/components/commons/WcCommonModal.vue';

export default {
  name: 'ListFilter',
  data() {
    return {
      filterObject: {},
    };
  },
  components: {
    ColorSelect,
    WcCommonModal,
  },
  created() {
    this.filterObject = Object.assign({}, this.filterObject, this.value);
  },
  props: {
    value: {
      type: Object,
      required: true,
    }
  },
  async mounted() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    close() {
      this.$emit('close');
    },
    returnFilter() {
      this.$emit('setFilter', this.filterObject);
    },
  }
}
</script>

<style scoped>
  .list-filter {
  }

  .color-row {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .color-col-btn {
    flex: 0 0 auto;
    padding-right: .5rem;
  }

  .color-col-sel {
    flex: 1 1;
    overflow-x: auto;
  }
</style>
