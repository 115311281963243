<template>
  <WcBottomModal title="確認刪除" @close="closeClicked" hide-confirm hide-close>
    <div>
      <div class="py-4">
        <p class="text-center m-0">刪除單品後無法恢復</p>
      </div>
      <div class="btn-list">
        <button @click="cancelClicked">取消</button>
        <button @click="confirmClicked">確認</button>
      </div>
    </div>
  </WcBottomModal>
</template>

<script>
// @ is an alias to /src
import WcBottomModal from '@/components/commons/WcBottomModal.vue';

export default {
  name: 'ClothDeleteModal',
  data() {
    return {
      
    };
  },
  created() {
    
  },
  props: {
    
  },
  components: {
    WcBottomModal,
  },
  computed: {
	},
  watch: {
    
  },
  async mounted() {
    
	},
  methods: {
    closeClicked() {
      this.$emit('close');
    },
    cancelClicked() {
      this.$emit('cancel');
    },
    confirmClicked() {
      this.$emit('confirm');
    },
  }
}
</script>

<style lang="scss" scoped>
  .btn-list {
    display: flex;
    width: calc(100% + 1.5rem);
    margin: 0 -.75rem -1rem;
    column-gap: 1px;
    button {
      flex: 1 1;
      border: none;
      background: var(--border-color-main);
      padding: .8rem .8rem 1.5rem;
      color: var(--icon-color-selection);
      font-size: 1rem;
      font-weight: 500;
    }
  }
</style>
