<template>
  <div class="cloth-list">
    <section class="header-tools" v-show="!isEmpty">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <a href="#" class="header-tool-btn" @click.prevent="showSortModal">
              <img :src="require('@/assets/icons/filter.png')" />
              排列順序
            </a>
          </div>

          <div class="col-6">
            <a href="#" class="header-tool-btn" @click.prevent="showFilterModal">
              <img :src="require('@/assets/icons/filter.png')" />
              篩選
            </a>
          </div>
        </div>
      </div>
    </section>

    <div class="container">
      <p class="no-cloth-hint" v-show="isEmpty">
        沒有公開中的單品<br/>
        請從衣櫥總覽中選取單品並設定為公開
      </p>
      <section class="category-list">
        <div class="category-cloths" v-for="(item) in parseCloths" :key="item.category.serial">
          <div class="row justify-content-between align-items-center no-gutters">
            <p class="cs-name">{{item.category.name}}</p>

            <a href="#" @click.prevent="openSelectClothModal(item.category.serial)" class="select-btn">選取</a>
          </div>

          <div class="d-flex align-items-center cloth-count">
            <img :src="require('@/assets/icons/cloth-icon.png')" style="width: 14px; display: block; margin-right: .25rem;"/>
            <span>{{item.cloths.length}}件</span>
          </div>

          <div class="row">
            <div class="col-4 position-relative mb-3" v-for="(cloth) in item.cloths" :key="cloth.serial">
              <div class="" @click="clothClicked(cloth.serial)">
                <ClothBlock :cloth="cloth" :categories="clothCategories">
                </ClothBlock>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>

    <transition name="fade">
      <ListSort :value="sortRule" v-if="isSortModalShow" @close="closeSortModal" @setSort="setSort"></ListSort>
    </transition>

    <transition name="fade">
      <ListFilter :value="listFilter" v-if="isFilterModalShow" @close="closeFilterModal" @setFilter="setFilter"></ListFilter>
    </transition>

    <transition name="fade">
      <FullScreenModal v-if="showViewCloth" title="檢視單品" @back="closeViewClothModal">
        <ViewCloth :id="focusClothSerial" @edit="openEditModal" @close="closeViewClothModal" hide-recover-btn></ViewCloth>
      </FullScreenModal>
    </transition>

    <transition name="fade">
      <CreateCloth v-if="showEditCloth" @updated="clothUpdated" :id="focusClothSerial" @back="editClothCancel"></CreateCloth>
    </transition>

    <transition name="fade">
      <FullScreenModal v-if="showSelectCloth" title="" @back="selectClothCancel">
        <ClothListSelect @updated="clothRecycleUpdated" :categoryName="toSelectName" :cloths="toSelectCloths" :categories="clothCategories" hide-delete hide-recycle></ClothListSelect>
      </FullScreenModal>
    </transition>

  </div>
</template>


<script>
import ClothBlock from '@/components/ClothBlock.vue';
import FullScreenModal from '@/components/FullScreenModal.vue';
import ViewCloth from '@/views/closet/ViewCloth.vue';
import CreateCloth from '@/views/closet/CreateCloth.vue';
import ClothListSelect from '@/views/closet/ClothListSelect.vue';
import ListFilter from '@/components/closet/ListFilter.vue';
import ListSort from '@/components/closet/ListSort.vue';
import { mapActions, mapState } from 'vuex';
import listFilterDefault from '@/dataModel/listFilter.js';

export default {
  name: 'PublicList',
  data() {
    return {
      init: false,
      sortRule: 0,   // 0: 新到舊, 1: 穿多到穿少, 2: 穿少到穿多, 3: 價低到價高, 4: 價高到價低
      parseCloths: [],

      toSelectName: '',
      toSelectCloths: [],

      focusClothSerial: -1,
      showViewCloth: false,
      showEditCloth: false,
      showSelectCloth: false,

      listFilter: {},
      isFilterModalShow: false,
      isSortModalShow: false,
    };
  },
  created() {
    this.listFilter = Object.assign({}, this.listFilter, listFilterDefault());
    this.readParamsFromRoute();
  },
  props: {
  },
  components: {
    ClothBlock,
    FullScreenModal,
    ViewCloth,
    CreateCloth,
    ClothListSelect,
    ListFilter,
    ListSort,
  },
  computed: {
    ...mapState(['cloths', 'clothCategories']),
    isEmpty() {
      return this.parseCloths.length === 0;
    }
	},
  beforeDestroy() {
  },
  watch: {
    cloths() {
      if (this.init) {
        this.parseData();
      }
    },
    // $route(to, from) {
    $route() {
      // console.log(`${JSON.stringify(from)} -> ${JSON.stringify(to)}`);
      this.readParamsFromRoute();
      this.parseData();
    }
  },
  mounted() {
    this.parseData();
    this.init = true;
	},
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'updateCloth']),
    showFilterModal() {
      this.isFilterModalShow = true;
    },
    showSortModal() {
      this.isSortModalShow = true;
    },
    setFilter(newFilter) {
      this.listFilter = Object.assign({}, this.listFilter, newFilter);
      this.parseData();
      this.closeFilterModal();
    },
    closeFilterModal() {
      this.isFilterModalShow = false;
    },
    setSort(newSort) {
      this.sortRule = newSort;
      this.parseData();
      this.closeSortModal();
    },
    closeSortModal() {
      this.isSortModalShow = false;
    },
    readParamsFromRoute() {
      const { params } = this.$route;
      if (typeof params.sortRule !== 'undefined') {
        this.sortRule = parseInt(params.sortRule);
      } else {
        this.sortRule = 0;
      }
    },
    parseData() {
      this.parseCloths.splice(0, this.parseCloths.length);
      for (const cs of this.clothCategories) {

        const cloths = this.cloths.filter((c) => {
          return !c.isDeleted && c.recycleType === -1 && c.category1 === cs.serial && c.isPublic;
        });

        const filteredCloths = cloths.filter((c) => {
          if (this.listFilter.color !== -1 && this.listFilter.color !== c.color) {
            return false;
          }
          return true;
        });

        if (filteredCloths.length > 0) {
          const sortedCloths = filteredCloths.sort((a, b) => {
            if (this.sortRule === 0) {
              return b.serial - a.serial;
            } else if (this.sortRule === 3 || this.sortRule === 4) {
              if (a.price === -1 && b.price === -1) {
                return b.serial - a.serial;
              } else if (a.price === -1) {
                return 1;
              } else if (b.price === -1) {
                return -1;
              } else {
                if (this.sortRule === 3) {
                  return b.price - a.price;
                } else {
                  return a.price - b.price;
                }
              }
            }
            return -1;
          });

          this.parseCloths.push({
            category: cs,
            cloths: sortedCloths,
          });
        }
      }
    },
    closeViewClothModal() {
      this.showViewCloth = false;
    },
    receiveError(err) {
      this.$emit('error', err);
    },
    clothClicked(id) {
      this.focusClothSerial = id;
      this.showViewCloth = true;
    },
    editClothCancel() {
      this.showEditCloth = false;
      this.showViewCloth = true;
    },
    openEditModal() {
      this.closeViewClothModal();
      this.showEditCloth = true;
    },
    clothUpdated(cloth) {
      this.showEditCloth = false;
      this.updateCloth(cloth);
    },
    clothRecycleUpdated(cloths) {
      for (const cloth of cloths) {
        this.updateCloth(cloth);
      }
      this.showSelectCloth = false;
    },
    selectClothCancel() {
      this.showSelectCloth = false;
    },
    openSelectClothModal(serial) {
      for (const categoryCloth of this.parseCloths) {
        if (categoryCloth.category.serial === serial) {
          this.toSelectCloths = categoryCloth.cloths;
          this.toSelectName = categoryCloth.category.name;
          break;
        }
      }
      this.showSelectCloth = true;
    },
    underconstruction() {
      this.showMsgModal('功能還在建立中~QQ');
    },
  }
}
</script>

<style scoped>
  .cloth-list {
  }

  .header-tools {
    background-color: #eeeeee;
    padding: 1rem 0;
    position: sticky;
    top: 0;
    z-index: 2;
  }

  a.header-tool-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    font-size: 12px;
    color: var(--text-dark);
    padding: .5rem;
  }

  a.header-tool-btn:hover {
    text-decoration: none;
  }

  a.header-tool-btn>img {
    width: 18px;
    height: 18px;
    object-fit: contain;
    display: block;
    margin-right: .5rem;
  }

  .no-cloth-hint {
    text-align: center;
    padding: 32px 16px 0;
    line-height: 3em;
  }

  .category-list {

  }

  a.select-btn {
    font-size: 12px;
    display: block;
    background-color: #eeeeee;
    border-radius: .5rem;
    padding: 2px .5rem;
    color: var(--text-dark);
  }

  a.select-btn:hover {
    text-decoration: none;
  }

  .category-cloths {
    padding: 1.5rem 0 .5rem;
  }

  .category-cloths:not(:first-child) {
    border-top: solid 1px #C4C4C4;
  }

  .cs-name {
    font-size: 18px;
    font-weight: 600;
    color: var(--text-dark);
    margin: 0;
  }

  .cloth-count {
    font-size: 12px;
    color: var(--text-light);
    margin-bottom: .5rem;
  }
</style>
