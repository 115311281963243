<template>
  <WcCommonModal title="選擇排序方式" @close="close" @cancel="close" @ok="setSort" >
    <div class="list-sort">
      <div class="mt-2 mb-3" v-for="(item) in sortOpts" :key="item.value">
        <input type="radio" v-model="sortValue" :value="item.value" :id="'sort-' + item.value"/>
        <label :for="'sort-' + item.value" class="d-inline-block pl-2 mb-0">{{item.name}}</label>
      </div>
    </div>
  </WcCommonModal>
</template>


<script>
import WcCommonModal from '@/components/commons/WcCommonModal.vue';

export default {
  name: 'ListSort',
  data() {
    return {

      sortValue: 0,
      sortOpts: [
        {
          value: 0,
          name: '由新增時間新到舊（預設）',
        },
        {
          value: 3,
          name: '由高價到低價',
        },
        {
          value: 4,
          name: '由低價到高價',
        },
        {
          value: 5,
          name: '穿著次數高到低',
        },
        {
          value: 6,
          name: '穿著次數低到高'
        }
      ]
    };
  },
  created() {
    this.sortValue = this.value;
  },
  props: {
    value: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  components: {
    WcCommonModal
  },
  computed: {

	},
  beforeDestroy() {
  },
  watch: {
  },
  mounted() {
	},
  methods: {
    close() {
      this.$emit('close');
    },
    setSort() {
      this.$emit('setSort', this.sortValue);
    }
  }
}
</script>

<style scoped>

</style>
