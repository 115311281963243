<template>
  <WcCommonModal title="編輯單品狀態" @close="closeModal" @ok="okClicked" hide-cancel>
    <div class="">
      <div class="mt-2 mb-3" v-for="(item) in optionMethods" :key="item.value">
        <input type="radio" v-model="selectMethod" :value="item.value" :id="'method-' + item.value"/>
        <label :for="'method-' + item.value" class="d-inline-block pl-2 mb-0">{{item.name}}</label>
      </div>
    </div>
  </WcCommonModal>
</template>


<script>
import WcCommonModal from '@/components/commons/WcCommonModal.vue';
import { mapActions } from 'vuex';

export default {
  name: 'EditStatusModal',
  data() {
    return {
      init: false,
      savingBusyName: 'RECYCLEMODALSAVING',

      selectMethod: -1,
      optionMethods: [
        {
          value: 1,
          name: '公開單品',
        },
        {
          value: 0,
          name: '取消公開',
        },
      ]
    };
  },
  created() {
  },
  props: {
    clothSerials: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
  },
  components: {
    WcCommonModal
  },
  computed: {
	},
  beforeDestroy() {
    this.clearComponentBusy(this.savingBusyName);
  },
  watch: {
  },
  mounted() {
	},
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    closeModal() {
      this.$emit('close');
    },
    async okClicked() {
      if (this.selectMethod === -1) {
        this.showMsgModal('請選取狀態');
        return;
      }
      this.appendComponentBusy(this.savingBusyName);
      try {
        const updatedCloths = [];
        for (const serial of this.clothSerials) {
          const updatedCloth = await this.$store.dispatch('api/publicClothPromise', {
            serial,
            isPublic: this.selectMethod === 1,
          });
          updatedCloths.push(updatedCloth);
        }
        this.$emit('updated', updatedCloths);
      } catch(err) {
        this.appendErrorMsg(err);
      } finally {
        this.clearComponentBusy(this.savingBusyName);
      }
    },
  }
}
</script>

<style scoped>

</style>
